<template>
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="product-page">
        <b-container>
          <div v-if="featuredImages.length">
            <b-row>
              <product-card 
                v-for="product in featuredImages" 
                :key="product.id" 
                :product="product" 
                :showLikeButton="false"
                :showShareButton="true"
               @toggle-like="updateLikeStatus" 
              />
            </b-row>
          </div>

          <div class="no-data mt-5 mb-5" v-else-if="!isLoading && !featuredImages.length">
            <div class="mx-auto text-center">
              <h2></h2>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </template>
  
  <script>
  import productCard from './imageCard.vue';
  import { mapState, mapActions } from 'vuex';
  
  export default {
  components: {
    productCard,
  },
  data() {
    return {
      isLoading: false,
      noDataMessage: 'No liked images found.',
    };
  },
  computed: {
    ...mapState('app', {
      featuredImages: state => state.featured_images,
    }),
  },
  mounted() {
    this.loadFeaturedImagesFromStorage();
    this.fetchFeaturedItems();
  },
  methods: {
    ...mapActions('app', ['fetchFeaturedItems', 'loadFeaturedImagesFromStorage']),
    async updateLikeStatus(imageId, likeStatus) {
      const user = JSON.parse(localStorage.getItem('userdetails'));
      const userId = user?.user_id;

      if (!userId) {
        console.error('User ID is not available in local storage');
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/api/updateLikeStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId, imageId, likeStatus })
        });

        const data = await response.json();

        if (data.message === 'Like status updated successfully!') {
          const image = this.featuredImages.find(img => img.id === imageId);
          if (image) {
            image.like_or_dislike = likeStatus;
            if (likeStatus === 'N') {
              this.featuredImages = this.featuredImages.filter(img => img.id !== imageId);
            }
          }
        }
      } catch (error) {
        console.error('Error updating like status:', error);
      }
    }
  }
};
</script>
  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  .swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 2px !important;
    padding-bottom: 15px !important;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  </style>
  